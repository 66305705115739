import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <path d="M3,14.95926 C3,8.35446 8.3544,3.00006 14.9592,3.00006 C21.5643,3.00006 26.9187,8.35446 26.9187,14.95926 C26.9187,21.56406 21.5643,26.91846 14.9592,26.91846 C8.3544,26.91846 3,21.56406 3,14.95926" />
    <path
      className="checkColor"
      d="M13.07682,20.23359 L13.07442,20.23359 C12.87492,20.23299 12.68352,20.15259 12.54342,20.01069 L7.80822,15.21759 C7.51692,14.92299 7.51992,14.44839 7.81452,14.15709 C8.10912,13.86609 8.58372,13.86879 8.87502,14.16369 L13.08012,18.41979 L20.95002,10.55019 C21.24312,10.25709 21.71772,10.25709 22.01052,10.55019 C22.30362,10.84299 22.30362,11.31789 22.01052,11.61069 L13.60722,20.01399 C13.46652,20.15439 13.27572,20.23359 13.07682,20.23359"
      fill="rgba(255,255,255,0.9)"
    />
  </React.Fragment>,
  'Success'
)
