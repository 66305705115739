import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { ManageLoader } from './components/ManageLoader'
import { initializeApm } from './apm'
import { createBrowserHistory } from 'history'
import { lazyLoadComponent } from 'lazyLoadComponent'
import { IS_TEST } from './constants/isttest'
import { GIT_SHA } from './constants/version'
import { APP_TYPE } from './constants/whereami'

const GlobalStyle = lazyLoadComponent(() => import('style/GlobalStyle'))
const Root = lazyLoadComponent(() => import('./Root'))

const browserHistory = createBrowserHistory({
  // override default window.alert user notification when a route change is blocked
  // use <RouterPrompt /> to display custom route change confirmation modal
  getUserConfirmation: () => {},
})

initializeApm()

if (!IS_TEST) {
  console.log('config:', window.CONFIG)
  console.log('release:', GIT_SHA)
  console.log('platform:', APP_TYPE)
}

// default browser behavior is to inc/dec a numeric text field on scroll;
// we disable this behavior (MAUI-387).
document.addEventListener('wheel', (e) => {
  // @ts-ignore
  if (document.activeElement?.type !== 'number') return
  // @ts-ignore
  document.activeElement?.blur?.()
})

window.fakeScan = (str: string) => {
  const delay = 10 // milliseconds between keypresses

  str.split('').forEach((char, index) => {
    setTimeout(() => {
      const event = new KeyboardEvent('keydown', {
        key: char,
        code: `Key${char.toUpperCase()}`,
        keyCode: char.charCodeAt(0),
        which: char.charCodeAt(0),
        bubbles: true,
        cancelable: true,
      })
      document.dispatchEvent(event)
    }, index * delay)
  })

  // Send Enter key after all characters
  setTimeout(() => {
    const enterEvent = new KeyboardEvent('keydown', {
      key: 'Enter',
      code: 'Enter',
      keyCode: 13,
      which: 13,
      bubbles: true,
      cancelable: true,
    })
    document.dispatchEvent(enterEvent)
  }, str.length * delay)
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <>
    <React.Suspense fallback={null}>
      <GlobalStyle />
    </React.Suspense>
    <React.Suspense fallback={<ManageLoader loadingMessage="" />}>
      <Root history={browserHistory} />
    </React.Suspense>
  </>
)
