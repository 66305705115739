import * as React from 'react'
import createSvgIcon from './createSvgIcon'

export default createSvgIcon(
  <React.Fragment>
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="6" fill="white" />
      <path
        d="M15 7.6875C10.0714 7.6875 5.89837 10.7555 4.4375 15C5.89837 19.2445 10.0714 22.3125 15 22.3125C19.9286 22.3125 24.1016 19.2445 25.5625 15C24.1016 10.7555 19.9286 7.6875 15 7.6875Z"
        stroke="black"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 19.0625C17.2437 19.0625 19.0625 17.2437 19.0625 15C19.0625 12.7563 17.2437 10.9375 15 10.9375C12.7563 10.9375 10.9375 12.7563 10.9375 15C10.9375 17.2437 12.7563 19.0625 15 19.0625Z"
        stroke="black"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>{' '}
  </React.Fragment>,
  'Eye Open'
)
